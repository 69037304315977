export const EMPLOYEE_LIST_REQUEST = "EMPLOYEE_LIST_REQUEST"; 
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_REQUEST_SUCCESS";
export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST"; 
export const EMPLOYEE_DETAIL_REQUEST = "EMPLOYEE_DETAIL_REQUEST"; 
export const EMPLOYEE_ARCHIVE_REQUEST = "EMPLOYEE_ARCHIVE_REQUEST"; 
export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST"; 
export const EMPLOYEE_UNARCHIVE_REQUEST = "EMPLOYEE_UNARCHIVE_REQUEST"; 
export const EMPLOYEE_EDIT_REQUEST = "EMPLOYEE_EDIT_REQUEST"; 
export const EMPLOYEE_CREATE_FORM_NAME = "createEmployee"; 
export const EMPLOYEE_EDIT_FORM_NAME = "editEmployee"; 
export const EMPLOYEE_PROMOTE_REQUEST = "EMPLOYEE_PROMOTE_REQUEST"; 
export const EMPLOYEE_JOB_HISTORY_REQUEST = "EMPLOYEE_JOB_HISTORY_REQUEST";
export const EMPLOYEE_JOB_HISTORY_SUCCESS = "EMPLOYEE_JOB_HISTORY_SUCCESS";
export const EMPLOYEE_AUTOCOMPLETE_REQUEST = "EMPLOYEE_AUTOCOMPLETE_REQUEST";
export const EMPLOYEE_AUTOCOMPLETE_SUCCESS = "EMPLOYEE_AUTOCOMPLETE_SUCCESS";
export const EMPLOYEE_TERMINATE_REQUEST = "EMPLOYEE_TERMINATE_REQUEST";
export const EMPLOYEE_ACTIVE_REQUEST = "EMPLOYEE_ACTIVE_REQUEST";
export const PROMOTE_EMPLOYEE_SUBMIT_RULES = { 
  positionId: {
    required: true
  },
  evaluationRate: {
    required: true
  },
  salary: {
    required: true
  },
  affectiveDate: {
    required: true
  },
  description: {
    required: true
  }
};

export const EMPLOYEE_STATUS_ACTIVE = 1;
export const EMPLOYEE_STATUS_ACTIVE_TITLE = "Active";
export const EMPLOYEE_STATUS_TERMINATE = -1;
export const EMPLOYEE_STATUS_TERMINATE_TITLE = "Terminated";

export const EMPLOYEE_STATUS_DATA_SOURCE = [
  {
    id: EMPLOYEE_STATUS_ACTIVE,
    name: EMPLOYEE_STATUS_ACTIVE_TITLE,
  },
  {
    id: EMPLOYEE_STATUS_TERMINATE,
    name: EMPLOYEE_STATUS_TERMINATE_TITLE,
  }
];

export const READ_EMPLOYEE = 139000;
export const CREATE_EMPLOYEE = 139001;
export const UPDATE_EMPLOYEE = 139002;
export const DELETE_EMPLOYEE = 139003 ;
export const ARCHIVE_EMPLOYEE = 139004;
export const UNARCHIVE_EMPLOYEE = 139005;
export const TERMINATE_EMPLOYEE = 139021;
export const ACTIVE_EMPLOYEE = 139022;

export const PROMOTE_EMPLOYEE = 139018;
export const JOB_HISTORY_EMPLOYEE = 145000;

export const READ_EMPLOYMENT_CARD_SETTING = 139014;
export const EDIT_EMPLOYMENT_CARD_SETTING = 139015;

export const EMPLOYEE_CODE_TYPE_MANUALLY_ID = 1;
export const EMPLOYEE_CODE_TYPE_MANUALLY_NAME = "Manually";
export const EMPLOYEE_CODE_TYPE_AUTO_ID = 2;
export const EMPLOYEE_CODE_TYPE_AUTO_NAME = "Auto";
export const EMPLOYEE_ATTENDANCE_APPROVER_SETTING_REQUEST = "EMPLOYEE_ATTENDANCE_APPROVER_SETTING_REQUEST";

// Employee Education
export const EDUCATION_REQUEST = "EDUCATION_REQUEST";
export const EDUCATION_SUCCESS = "EDUCATION_SUCCESS";
export const EDUCATION_FAILURE = "EDUCATION_FAILURE";

export const CREATE_EDUCATION_REQUEST = "CREATE_EDUCATION_REQUEST";
export const CREATE_EDUCATION_SUCCESS = "CREATE_EDUCATION_SUCCESS";

export const EDUCATION_ADD_NEW = "EDUCATION_ADD_NEW";
export const EDUCATION_DELETE = "EDUCATION_DELETE";

export const EDIT_EDUCATION_REQUEST = "EDIT_EDUCATION_REQUEST";
export const EDIT_EDUCATION_SUCCESS = "EDIT_EDUCATION_SUCCESS";

export const DELETE_EDUCATION_REQUEST = "DELETE_EDUCATION_REQUEST";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";

export const EMPLOYMENT_CARD_AND_SIGNATURE_REQUEST = "EMPLOYMENT_CARD_AND_SIGNATURE_REQUEST";
export const EMPLOYMENT_CARD_AND_SIGNATURE_SUCCESS = "EMPLOYMENT_CARD_AND_SIGNATURE_SUCCESS";

export const EMPLOYMENT_CARD_AND_SIGNATURE_DETAIL_REQUEST = "EMPLOYMENT_CARD_AND_SIGNATURE_DETAIL_REQUEST";
export const EMPLOYMENT_CARD_AND_SIGNATURE_EDIT_REQUEST = "EMPLOYMENT_CARD_AND_SIGNATURE_EDIT_REQUEST";

export const EMPLOYMENT_CARD_AND_SIGNATURE_EDIT_FORM = "editEmploymentAndSignature";

export const EMPLOYMENT_CARD_SETTING_SUBMIT_RULES = {
  employmentCardId: {
    required: true
  },
  signatureImageUrl: {
    required: true
  }
};

export const EDUCATION_FORM_NAME = "educationForm";

export const EDUCATION_SUBMIT_RULES = {
  school: {
    required: true
  },
  from: {
    required: true
  },
  to: {
    required: true
  },
  degree: {
    required: true
  },
  major: {
    required: true
  }
};

export const EMPLOYEE_EDUCATION_MODAL_ID = 1;
export const EMPLOYEE_WORK_EXPERIENCE_MODAL_ID = 2;
export const EMPLOYEE_LANGUAGE_MODAL_ID = 3;

export const EMPLOYEE_EDUCATION_MODAL_TITLE = "Education";
export const EMPLOYEE_WORK_EXPERIENCE_MODAL_TITLE = "Work Experience";
export const EMPLOYEE_LANGUAGE_MODAL_TITLE = "Language";

// Employee Work Experience
export const WORK_EXPERIENCE_REQUEST = "WORK_EXPERIENCE_REQUEST";
export const WORK_EXPERIENCE_SUCCESS = "WORK_EXPERIENCE_SUCCESS";

export const CREATE_WORK_EXPERIENCE_REQUEST = "CREATE_WORK_EXPERIENCE_REQUEST";
export const CREATE_WORK_EXPERIENCE_SUCCESS = "CREATE_WORK_EXPERIENCE_SUCCESS";

export const WORK_EXPERIENCE_ADD_NEW = "WORK_EXPERIENCE_ADD_NEW";
export const WORK_EXPERIENCE_DELETE = "WORK_EXPERIENCE_DELETE";

export const EDIT_WORK_EXPERIENCE_REQUEST = "EDIT_WORK_EXPERIENCE_REQUEST";
export const EDIT_WORK_EXPERIENCE_SUCCESS = "EDIT_WORK_EXPERIENCE_SUCCESS";

export const DELETE_WORK_EXPERIENCE_REQUEST = "DELETE_WORK_EXPERIENCE_REQUEST";
export const DELETE_WORK_EXPERIENCE_SUCCESS = "DELETE_WORK_EXPERIENCE_SUCCESS";

export const WORK_EXPERIENCE_FORM_NAME = "workExperienceForm";

export const WORK_EXPERIENCE_SUBMIT_RULES = {
  company: {
    required: true
  },
  period: {
    required: true
  },
  from: {
    required: true
  },
  to: {
    required: true
  },
  position: {
    required: true
  }
};

// Employee Language
export const LANGUAGE_REQUEST = "LANGUAGE_REQUEST";
export const LANGUAGE_SUCCESS = "LANGUAGE_SUCCESS";

export const CREATE_LANGUAGE_REQUEST = "CREATE_LANGUAGE_REQUEST";
export const CREATE_LANGUAGE_SUCCESS = "CREATE_LANGUAGE_SUCCESS";

export const LANGUAGE_ADD_NEW = "LANGUAGE_ADD_NEW";
export const LANGUAGE_DELETE = "LANGUAGE_DELETE";

export const EDIT_LANGUAGE_REQUEST = "EDIT_LANGUAGE_REQUEST";
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";

export const DELETE_LANGUAGE_REQUEST = "DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";

export const LANGUAGE_FORM_NAME = "languageForm";

export const LANGUAGE_SUBMIT_RULES = {
  language: {
    required: true,
  },
  readingLevel: {
    required: true,
    message: "Please Select One"
  },
  writingLevel: {
    required: true,
    message: "Please Select One"
  },
  speakingLevel: {
    required: true,
    message: "Please Select One"
  },
  listeningLevel: {
    required: true,
    message: "Please Select One"
  }
};

export const LANGUAGE_STATUS_BAD = 1;
export const LANGUAGE_STATUS_BAD_TITLE = "Bad";

export const LANGUAGE_STATUS_GOOD = 2;
export const LANGUAGE_STATUS_GOOD_TITLE = "Good";

export const LANGUAGE_STATUS_AVERAGE = 3;
export const LANGUAGE_STATUS_AVERAGE_TITLE = "Average";

export const LANGUAGE_STATUS_EXCELLENT = 4;
export const LANGUAGE_STATUS_EXCELLENT_TITLE = "Excellent";

export const RADIO_BUTTON_DATA = [
  {
    id: LANGUAGE_STATUS_BAD,
    name: LANGUAGE_STATUS_BAD_TITLE
  },
  {
    id: LANGUAGE_STATUS_GOOD,
    name: LANGUAGE_STATUS_GOOD_TITLE,
  },
  {
    id: LANGUAGE_STATUS_AVERAGE,
    name: LANGUAGE_STATUS_AVERAGE_TITLE,
  },
  {
    id: LANGUAGE_STATUS_EXCELLENT,
    name: LANGUAGE_STATUS_EXCELLENT_TITLE,
  }
];

// Employee Contact 
export const EMPLOYEE_CONTACT_REQUEST = "EMPLOYEE_CONTACT_REQUEST";
export const EMPLOYEE_CONTACT_SUCCESS = "EMPLOYEE_CONTACT_SUCCESS";

export const EDIT_EMPLOYEE_CONTACT_REQUEST = "EDIT_EMPLOYEE_CONTACT_REQUEST";
export const EDIT_EMPLOYEE_CONTACT_SUCCESS = "EDIT_EMPLOYEE_CONTACT_SUCCESS";

// Employee Payroll Settings
export const READ_EMPLOYEE_PAYROLL_SETTING_CODE = 169000;
export const CRATE_EMPLOYEE_PAYROLL_SETTING_CODE = 169001;
export const UPDATE_EMPLOYEE_PAYROLL_SETTING_CODE = 169002;
export const DELETE_EMPLOYEE_PAYROLL_SETTING_CODE = 169003;
export const ARCHIVE_EMPLOYEE_PAYROLL_SETTING_CODE = 169004;
export const UNARCHIVE_EMPLOYEE_PAYROLL_SETTING_CODE = 169005;
export const ACTIVE_EMPLOYEE_PAYROLL_SETTING_CODE = 169011;
export const DEACTIVE_EMPLOYEE_PAYROLL_SETTING_CODE = 169012;

export const EMPLOYEE_PAYROLL_REQUEST = "EMPLOYEE_PAYROLL_REQUEST";
export const EMPLOYEE_PAYROLL_SUCCESS = "EMPLOYEE_PAYROLL_SUCCESS";

export const EMPLOYEE_PAYROLL_CREATE_REQUEST = "EMPLOYEE_PAYROLL_CREATE_REQUEST";
export const EMPLOYEE_PAYROLL_CREATE_SUCCESS = "EMPLOYEE_PAYROLL_CREATE_SUCCESS";

export const EMPLOYEE_PAYROLL_EDIT_REQUEST = "EMPLOYEE_PAYROLL_EDIT_REQUEST";
export const EMPLOYEE_PAYROLL_EDIT_SUCCESS = "EMPLOYEE_PAYROLL_CREATE_SUCCESS";

export const EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY = 1;
export const EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY_TITLE = "Monthly";
export const EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY = 2;
export const EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY_TITLE = "Hourly";
export const salaryTypes = [
  {
    id: EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY,
    name: EMPLOYEE_PAYROLL_SALARY_TYPE_MONTHLY_TITLE,
  },
  {
    id: EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY,
    name: EMPLOYEE_PAYROLL_SALARY_TYPE_HOURLY_TITLE,
  }
];

export const EMPLOYEE_PAYROLL_BANK_LIST = [
  { id: 1, name: "ABA" },
  { id: 2, name: "ACLEDA" }
];

export const EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK = 1;
export const EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK_TITLE = "Bank";
export const EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH = 2;
export const EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH_TITLE = "Cash";
export const payrollPaymentMethods = [
  {
    id: EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK,
    name: EMPLOYEE_PAYROLL_PAYMENT_METHOD_BANK_TITLE,
  },
  {
    id: EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH,
    name: EMPLOYEE_PAYROLL_PAYMENT_METHOD_TYPE_CASH_TITLE,
  }
];

export const EMPLOYEE_COMMISSION_SUBMIT_RULES = {
  itemSaleCommissionId: {
    required: true
  },
  employeeSaleCommissionId: {
    required: true
  },
};

export const EMPLOYEE_PAYROLL_EDIT_FORM = "editEmployeePayrollForm";
export const EMPLOYEE_PAYROLL_CREATE_FORM = "createEmployeePayrollForm";

export const PAYROLL_STATUS_ACTIVE = 1;
export const PAYROLL_STATUS_DEACTIVE = -1;

// Payroll Incentive
export const EMPLOYEE_PAYROLL_INCENTIVES_FIELD_NAME = "employeePayrollIncentives";
export const PAYROLL_INCENTIVE_CREATE_REQUEST = "PAYROLL_INCENTIVE_CREATE_REQUEST";
export const EMPLOYEE_PAYROLL_INCENTIVES_REQUEST = "EMPLOYEE_PAYROLL_INCENTIVES_REQUEST";
export const CREATE_EMPLOYEE_PAYROLL_INCENTIVE = 233011;

// Employee Leave Settings
export const READ_EMPLOYEE_LEAVE_SETTING_CODE = 139011;
export const CREATE_EMPLOYEE_LEAVE_SETTING_CODE = 139012;
export const UPDATE_EMPLOYEE_LEAVE_SETTING_CODE = 139013;
export const VIEW_EMPLOYEE_LEAVE_BALANCE = 149000;

// Employee Sale Commission
export const READ_EMPLOYEE_SALE_COMMISSION = 198030;
export const UPDATE_EMPLOYEE_SALE_COMMISSION = 198031;

export const EMPLOYEE_LEAVE_SETTING_REQUEST = "EMPLOYEE_LEAVE_SETTING_REQUEST";
export const EMPLOYEE_LEAVE_SETTING_SUCCESS = "EMPLOYEE_LEAVE_SETTING_SUCCESS";

export const EMPLOYEE_LEAVE_SETTING_CREATE_REQUEST = "EMPLOYEE_LEAVE_SETTING_CREATE_REQUEST";
export const EMPLOYEE_LEAVE_SETTING_EDIT_REQUEST = "EMPLOYEE_LEAVE_SETTING_EDIT_REQUEST";

export const EMPLOYEE_LEAVE_BALANCE_HISTORY_REQUEST = "EMPLOYEE_LEAVE_BALANCE_HISTORY_REQUEST";
export const EMPLOYEE_LEAVE_BALANCE_HISTORY_SUCCESS = "EMPLOYEE_LEAVE_BALANCE_HISTORY_REQUEST_SUCCESS";

export const EMPLOYEE_SALE_COMMISSION_REQUEST = "EMPLOYEE_SALE_COMMISSION_REQUEST";
export const EMPLOYEE_SALE_COMMISSION_SUCCESS = "EMPLOYEE_SALE_COMMISSION_REQUEST_SUCCESS";
export const UPDATE_EMPLOYEE_SALE_COMMISSION_REQUEST = "UPDATE_EMPLOYEE_SALE_COMMISSION_REQUEST";
export const EMPLOYEE_SALE_COMMISSION_AUTO_COMPLETE_REQUEST = "EMPLOYEE_SALE_COMMISSION_AUTO_COMPLETE_REQUEST";
export const EMPLOYEE_COMMISSION_FORM_NAME = "employeeSaleCommission";

export const EMPLOYEE_LEAVE_SETTING_EDIT_FORM = "editLeaveSettingForm";

export const EMPLOYEE_LEAVE_SETTING_LEAVE_APPROVER_FIELD_NAME = "approvers";
export const EMPLOYEE_LEAVE_SETTING_LEAVE_POLICY_ID_FIELD_NAME = "employeeLeavePolicies";

export const EMPLOYEE_LEAVE_SETTING_CREATE_LEAVE_APPROVER_FORM_NAME = "createLeaveApproverForm";
export const EMPLOYEE_LEAVE_SETTING_EDIT_LEAVE_APPROVER_FORM_NAME = "editLeaveApproverForm";

export const EMPLOYEE_LEAVE_SETTING_CREATE_LEAVE_TYPE_AND_POLICY_FORM_NAME = "createLeaveTypeAndPolicyForm";
export const EMPLOYEE_LEAVE_SETTING_EDIT_LEAVE_TYPE_AND_POLICY_FORM_NAME = "editLeaveTypeAndPolicyForm";

export const EMPLOYEE_LEAVE_SETTING_SUBMIT_RULES = {
  approvers: {
    required: true,
    type: "arrayInline",
    message: "Please add at least 1 approver",
    subFields: [
      {
        name: "approverId"
      },
    ],
    validation: (values) => {
      let isError = true;
      values.map((subValues) => {
        if (subValues.required) {
          isError = false;
        }
      });
      if (isError) {
        return {
          message: "mandatory required at least 1 approver"
        }; 
      }
    }
  },
  employeeLeavePolicies: {
    required: true,
    type: "arrayInline",
    message: "Please add at least 1 leave type and leave policy",
    subFields: [
      {
        name: "leaveTypeId"
      },
      {
        name: "leavePolicyId"
      },
    ],
  }
};