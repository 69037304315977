export const POS_ITEM_REQUEST = "POS_ITEM_REQUEST";
export const POS_ITEM_SUCCESS = "POS_ITEM_REQUEST_SUCCESS";

export const POS_PAY_REQUEST = "POS_PAY_REQUEST";
export const POS_PAY_SUCCESS = "POS_PAY_REQUEST_SUCCESS";

export const POS_ITEM_MODAL_TYPE_PRODUCT_DETAIL = 1;
export const POS_ITEM_MODAL_TYPE_SERIAL_DETAIL = 2;
export const POS_ITEM_SERIAL_FORM_NAME = "posItemSerial";

// Invoice
export const POS_INVOICE_REQUEST = "POS_INVOICE_REQUEST";

// Total Balance
export const POS_TOTAL_BALANCE_REQUEST = "POS_TOTAL_BALANCE_REQUEST";
export const POS_TOTAL_BALANCE_SUCCESS = "POS_TOTAL_BALANCE_REQUEST_SUCCESS"; 

export const POS_ITEM_SUBMIT_RULES = {
  customerId: {
    required: true,
  },
};

export const POS_ITEM_SERIAL_SUBMIT_RULES = {
  serialNumber: {
    required: true,
  },
};

export const POS_SESSION_TYPE_OPEN_REGISTER = 1;
export const POS_SESSION_TYPE_OPEN_REGISTER_TITLE = "Open Register";
export const POS_SESSION_TYPE_CLOSE_REGISTER = 2;
export const POS_SESSION_TYPE_CLOSE_REGISTER_TITLE = "Close Register";
export const POS_SESSION_TYPE_PRODUCT_DETAIL = 3;
export const POS_SESSION_TYPE_PRODUCT_DETAIL_TITLE = "Product Detail";
export const POS_SESSION_TYPE_SCAN_SERIAL = 4;
export const POS_SESSION_TYPE_SERIAL_DETAIL = 5;
export const POS_SESSION_TYPE_SERIAL_DETAIL_TITLE = "Serial Detail";
export const POS_SESSION_TYPE_PAY = 6;
export const POS_SESSION_TYPE_PAY_TITLE = "Pay";
export const POS_SESSION_TYPE_PROMOTION = 7;
export const POS_SESSION_TYPE_PROMOTION_TITLE = "Promotion";

export const POS_SESSION_STATUS_OPEN_REGISTER = 1;
export const POS_SESSION_STATUS_CLOSE_REGISTER = 2;

export const POS_SESSION_CREATE_FORM_NAME = "createPosSession";
export const POS_SESSION_CLOSE_FORM_NAME = "closePosSession";
export const POS_FORM_NAME = "posForm";
export const POS_ITEM_ARRAY_FIELD_FIELD_NAME = "posItems";

export const POS_SESSION_REQUEST = "POS_SESSION_REQUEST";
export const POS_SESSION_SUCCESS = "POS_SESSION_SUCCESS";

export const POS_SESSION_CREATE_REQUEST = "POS_SESSION_CREATE_REQUEST";
export const POS_SESSION_CLOSE_REQUEST = "POS_SESSION_CLOSE_REQUEST";

export const POS_DEFAULT_PAYMENT_METHOD = 1;
export const POS_CURRENCY_IN_KHR = 1;

export const READ_POS = 226000;
export const SET_FULL_SCREEN = "SET_FULL_SCREEN";

export const READ_POS_SETTING = 227004;
export const UPDATE_POS_SETTING = 227003;

export const POS_SETTING_PATH = "/point-of-sale/setting";

export const CURRENCY_KHMER_DATA_SOURCE = [
  {
    value: 100000
  },
  {
    value: 50000
  },
  {
    value: 10000
  },
  {
    value: 5000
  },
  {
    value: 2000
  },
  {
    value: 1000
  },
  {
    value: 500
  },
  {
    value: 100
  },
];

export const USD_DATA_SOURCE = [
  {
    value: 100
  },
  {
    value: 50
  },
  {
    value: 20
  },
  {
    value: 10
  },
  {
    value: 5
  },
  {
    value: 1
  },
];